html {
  min-width: 360px;
}

nav {
  height: 90px;
}

.nav-logo {
  float: left !important;
  max-height: 80px;
}

/* change navbar background on collapse */
@media (max-width: 575px) {
  .navbar-collapse {
    background-color: rgb(240, 240, 240);
    border-radius: 0.25em;
    z-index: 999;
    padding: 1em;
    margin-top: -0.5em;
    border: 1px solid black;
  }
  .navbar-collapse>ul>li>a {
    color: black !important;
  }
}

.bg-info, .badge-info {
  background-color: teal !important;
}

.bg-tan, .badge-soil {
  background-color: saddlebrown!important;
}

.bg-blue, .badge-blue {
  background-color: royalblue !important;
}

.text-info {
  color: teal !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.table-responsive {
  background-color: #212529;
}

/* override styles when printing */
@media print {
  @page {
    size: A4 landscape;
  }

  html, body, #root, #root>div {
    height: 100% !important;
    background-color: #fff !important;
  }

  .App, .FeedChart, #jumbotron, #jumbotron small, .table-responsive, tr, th, td {
    background-color: #fff !important;
    color: black !important;
    --bs-table-accent-bg: #fff !important;
    --bs-table-bg: #fff !important;
  }

  .table-responsive {
    max-width: 100vw;
  }

  #base-select, #media-select {
    display: none !important;
  }

  #NavBar {
    position: absolute;
    width: 90px !important;
  }

  #Navbar nav {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #NavBar ul {
    display: none !important;
  }
}